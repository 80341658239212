import cabbage from './cabbage.webp';
import pancake from './pancake.webp';
import pierogie from './pierogie.webp';
import potatoes from './potatoes.webp';
import deli from './handpie.webp';
import freezer from './freezer.jpeg';
import dessert from './dessert2.JPG';
import dessert2 from './dessert.JPG';
import dessert3 from './dessert3.JPG';
import peppers from './peppers.JPG';
import pies from './pies.JPG';
import sweets from './sweets.jpeg';
import yummy2 from './yummy2.jpeg';

const images = {
  food: [
    { src: cabbage, alt: "Cabbage Rolls" },
    { src: pancake, alt: "Potato Pancake" },
    { src: pierogie, alt: "Pierogies" },
    { src: potatoes, alt: "Roasted Potatoes" }
  ],
  marketSections: [
    {
      title: "European Fresh Deli",
      images: [{ src: deli, alt: "Deli Meats and Cheeses" }, { src: peppers, alt: "Peppers" }, { src: pies, alt: "Pies" }]
    },
    {
      title: "European Grocery Store",
      images: [{ src: freezer, alt: "European Grocery Store" }, { src: sweets, alt: "Sweets" }, { src: yummy2, alt: "Yummy Treats" }]
    },
    {
      title: "Homemade Desserts",
      images: [{ src: dessert, alt: "Homemade Dessert" }, { src: dessert2, alt: "Homemade Dessert" }, { src: dessert3, alt: "Homemade Dessert" }]
    }
  ]
};

export default images;
