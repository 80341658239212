import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './logofull.jpg';
import menu from './menu.png';
import breakfastMenu from './breakfastmenu.webp';
import images from './images';

const App = () => {
  const [menuState, setMenuState] = useState({ isOpen: false, image: null });
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 100);
  }, []);

  const openMenu = (image) => setMenuState({ isOpen: true, image });
  const closeMenu = (e) => e.target.className === 'modal' && setMenuState({ isOpen: false, image: null });

  const MenuButton = ({ text, image }) => (
    <button className="cta-button fade-in" onClick={() => openMenu(image)}>
      {text}
    </button>
  );

  return (
    <div className={`App ${loaded ? 'loaded' : ''}`}>
      <header className="App-header fade-in">
        <img src={logo} alt="Pierogi House | Grill and European Goods" className="logo" />
      </header>

      <section className="about-us fade-in-up" style={{ backgroundColor: '#d9c378', color: '#000' }}>
        <h2>Now OPEN in Parma Heights!</h2>
        <p>
          We bring traditional European flavors to you with our market and grill.
          Shop for hard-to-find European goods and enjoy freshly prepared meals.
        </p>
        <div className="button-group">
          <MenuButton text="Menu" image={menu} />
          <button className="cta-button call-button fade-in" onClick={() => window.location.href = 'tel:+14405582413'}>
            440-558-2413
          </button>
          <button className="cta-button catering-button fade-in" onClick={() => window.location.href = 'mailto:bazarandgrill@gmail.com?subject=Catering Inquiry'}>
            Email Catering
          </button>
          <button className="cta-button location-button fade-in" onClick={() => window.open('https://www.google.com/maps/search/?api=1&query=6619+Pearl+Rd+Parma+Heights+OH+44130', '_blank')}>
            Directions
          </button>
        </div>
      </section>

      {menuState.isOpen && (
        <div className="modal fade-in" onClick={closeMenu}>
          <div className="modal-content slide-in" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={() => setMenuState({ isOpen: false, image: null })}>&times;</span>
            <img src={menuState.image} alt="Menu" className="menu-image fade-in" />
          </div>
        </div>
      )}

      <section className="gallery fade-in-up">
        {images.food.map((img, idx) => (
          <img key={idx} src={img.src} alt={img.alt} className="gallery-img hover-scale" />
        ))}
      </section>

      <section className="market fade-in-up">
        <h2>Visit our Market @ 6619 Pearl Rd Parma Heights, OH 44130</h2>

        <div className="business-hours-container fade-in-up" style={{ backgroundColor: '#d9c378', color: '#000' }}>
          <h3><span className="clock-icon">🕒</span> Hours of Business</h3>
          <p>Monday - Saturday: 7am - 9pm</p>
          <p>Sunday: 7am - 6pm</p>
        </div>

        {images.marketSections.map((section, idx) => (
          <div key={idx} className="market-section fade-in-up">
            <h3>{section.title}</h3>
            <div className="market-gallery">
              {section.images.map((img, idy) => (
                <img key={idy} src={img.src} alt={img.alt} className="market-img hover-scale" />
              ))}
            </div>
          </div>
        ))}
      </section>

      <footer className="fade-in" style={{ backgroundColor: '#000', color: '#d9c378' }}>
        <p>© 2025 Pierogi House | Grill and European Goods. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default App;
